import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  partners: [], // partners === resellers from api
  initialized: false,
  partnerProfileInitialized: false,
  partnerProfileDetails: {},
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PARTNERS SUCCESS
    getPartnersSuccess(state, action) {
      const resellers = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.partners = resellers;
    },

    // UPDATE PARTNERS STATUS
    updatePartnerStatus(state, action) {
      const reseller = action.payload;
      state.isLoading = false;
      state.initialized = true;
      const updated = [...state.partners];
      const toUpdate = updated.findIndex(
        (resellerItem) => resellerItem.reseller_id === reseller.reseller_id
      );
      updated[toUpdate].status = reseller.status;
      state.partners = updated;
    },
    // UPDATE PARTNERSPROFILEDETAILS STATUS
    updatepartnerProfileDetailsSuccess(state, action) {
      const profileDetails = action.payload;
      state.isLoading = false;
      state.partnerProfileInitialized = true;
      state.partnerProfileDetails = profileDetails;
    },
  },
});

// Actions
export const { getPartnersSuccess } = partnersSlice.actions;

// Reducer
export default partnersSlice.reducer;

export const getAllPartners = (state) => state.partners;

export function getPartners() {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const response = await axios.get('/lms_user/api/reseller');
      dispatch(partnersSlice.actions.getPartnersSuccess(response.data.resellers));
    } catch (error) {
      dispatch(partnersSlice.actions.hasError(error));
    }
  };
}

export function changeStatus(resellerId) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const response = await axios.get(`/lms_user/api/reseller_status/${resellerId}`);
      dispatch(partnersSlice.actions.updatePartnerStatus(response.data.reseller));
    } catch (error) {
      dispatch(partnersSlice.actions.hasError(error));
    }
  };
}

export function addPartner(data) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('reseller_name', data?.name);
      formData.append('reseller_type', data?.resellerType);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('location', data?.location);
      formData.append('user_name', data?.userName);
      formData.append('password', data?.password);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller`,
        data: formData,
      });
      dispatch(partnersSlice.actions.getPartnersSuccess(response.data.resellers));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function updatePartner(data, resellerId) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('_method', 'patch');
      formData.append('reseller_name', data?.name);
      formData.append('reseller_type', data?.resellerType);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('location', data?.location);
      formData.append('user_name', data?.userName);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller/${resellerId}`,
        data: formData,
      });
      dispatch(partnersSlice.actions.getPartnersSuccess(response.data.resellers));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function resetPassword(data) {
  return async () => {
    try {
      await axios({
        method: 'post',
        url: `/lms_user/api/direct_password_reset`,
        params: {
          new_password: data?.newPassword,
          confirm_password: data?.confirmPassword,
          user_type: data?.userType,
          reseller_id: data?.resellerId,
          user_id: data?.userId,
        },
      });
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function manageAccount(data) {
  return async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/direct_manage_account`,
        params: {
          user_type: 'reseller',
          reseller_id: data?.resellerId,
          user_id: data?.userId,
        },
      });
      const { access_token, refresh_token } = response.data;
      return { access_token, refresh_token };
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

// UPDATE PARTNER PROFILE DETAILS
export function getPartnersProfileDetails() {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const response = await axios.get('/lms_user/api/reseller_profile');
      dispatch(partnersSlice.actions.updatepartnerProfileDetailsSuccess(response.data));
    } catch (error) {
      dispatch(partnersSlice.actions.hasError(error));
    }
  };
}

export function updatepartnerProfileDetails(data) {
  return async (dispatch) => {
    dispatch(partnersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('reseller_name', data?.name);
      formData.append('mobile', data?.phoneNumber);
      formData.append('email', data?.email);
      formData.append('location', data?.location);
      formData.append('website', data?.websiteUrl);
      formData.append('about', data?.about);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/reseller_profile`,
        data: formData,
      });
      dispatch(partnersSlice.actions.updatepartnerProfileDetailsSuccess(response.data));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
