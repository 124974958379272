// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  register: '/register',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
  },
  users: {
    root: path(ROOTS_DASHBOARD, '/users'),
    addUser: path(ROOTS_DASHBOARD, '/users/add-user'),
    edit: (name) => path(ROOTS_DASHBOARD, `/users/edit/${name}`),
  },
  customers: {
    root: path(ROOTS_DASHBOARD, '/customers'),
    addCustomer: path(ROOTS_DASHBOARD, '/customers/add-customer'),
    edit: (name) => path(ROOTS_DASHBOARD, `/customers/edit/${name}`),
    accountManager: path(ROOTS_DASHBOARD, '/customers/account-manager'),
  },
  partners: {
    root: path(ROOTS_DASHBOARD, '/partners'),
    setup: path(ROOTS_DASHBOARD, '/partners/account-setup'),
    partnerProfile: path(ROOTS_DASHBOARD, '/partners/profile'),
    addPartner: path(ROOTS_DASHBOARD, '/partners/add-partner'),
    edit: (name) => path(ROOTS_DASHBOARD, `/partners/edit/${name}`),
  },
  googleWorkspace: {
    root: path(ROOTS_DASHBOARD, '/google-workspace'),
    customers: path(ROOTS_DASHBOARD, '/google-workspace/customers'),
    workspaceCustomerInfo: path(ROOTS_DASHBOARD, '/google-workspace/customers/info'),
    transactions: path(ROOTS_DASHBOARD, '/google-workspace/transactions'),
  },
  whatsApp: {
    root: path(ROOTS_DASHBOARD, '/whatsapp'),
    settings: path(ROOTS_DASHBOARD, '/whatsapp/settings'),
    templates: path(ROOTS_DASHBOARD, '/whatsapp/templates'),
    rates: path(ROOTS_DASHBOARD, '/whatsapp/rates'),
    partners: path(ROOTS_DASHBOARD, '/whatsapp/partners'),
    customers: path(ROOTS_DASHBOARD, '/whatsapp/customers'),
    transactions: path(ROOTS_DASHBOARD, '/whatsapp/transactions'),
    customizeDemo: path(ROOTS_DASHBOARD, '/whatsapp/customize-demo'),
    fixDisplayName: path(ROOTS_DASHBOARD, '/whatsapp/fix-display-name'),
  },
  reports: path(ROOTS_DASHBOARD, '/reports'),

  // Whitelable Config
  whitelableConfig: path(ROOTS_DASHBOARD, '/whitelable-config'),
  // leadsetup: path(ROOTS_DASHBOARD, '/leadsetup'),
};
