import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  customers: [], // customers === orgs from api
  initialized: false,
};

const customersSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMERS SUCCESS
    getCustomersSuccess(state, action) {
      const orgs = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.customers = orgs;
    },
  },
});

// Actions
export const { getCustomersSuccess } = customersSlice.actions;

// Reducer
export default customersSlice.reducer;

export const getAllCustomers = (state) => state.customers;

export function getCustomers() {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      // const response = await axios.get('/lms_user/api/org');
      const response = await axios.get('/lms_user/api/all_org');
      dispatch(customersSlice.actions.getCustomersSuccess(response.data.orgs));
    } catch (error) {
      dispatch(customersSlice.actions.hasError(error));
    }
  };
}

export function changeStatus(org_id) {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      const response = await axios.get(`/lms_user/api/org_status/${org_id}`);
      dispatch(customersSlice.actions.getCustomersSuccess(response.data.orgs));
    } catch (error) {
      dispatch(customersSlice.actions.hasError(error));
    }
  };
}

export function addCustomer(data) {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('org_name', data?.name);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('password', data?.password);
      formData.append('location', data?.location);
      formData.append('about', data?.about);
      formData.append('user_name', data?.userName);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/org`,
        data: formData,
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data.orgs));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function updateCustomer(data, orgId) {
  return async (dispatch) => {
    dispatch(customersSlice.actions.startLoading());
    try {
      const formData = new FormData();
      formData.append('_method', 'patch');
      formData.append('org_name', data?.name);
      formData.append('email', data?.email);
      formData.append('mobile', data?.phoneNumber);
      formData.append('location', data?.location);
      formData.append('about', data?.about);
      formData.append('user_name', data?.userName);
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/org/${orgId}`,
        data: formData,
      });
      dispatch(customersSlice.actions.getCustomersSuccess(response.data.orgs));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function resetPassword(data) {
  return async () => {
    try {
      await axios({
        method: 'post',
        url: `/lms_user/api/direct_password_reset`,
        params: {
          new_password: data?.newPassword,
          confirm_password: data?.confirmPassword,
          user_type: data?.userType,
          org_id: data?.orgId,
          user_id: data?.userId,
        },
      });
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function manageAccount(data) {
  return async () => {
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/direct_manage_account`,
        params: {
          user_type: data?.userType,
          org_id: data?.orgId,
          user_id: data?.userId,
        },
      });
      const { access_token, refresh_token } = response.data;
      return { access_token, refresh_token };
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function updateAccManager(data) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/account_manager`,
        params: {
          org_id: data?.orgId,
          users: data?.users,
          primary: data?.primaryAm,
        },
      });
      const { orgs } = response.data;
      dispatch(customersSlice.actions.getCustomersSuccess(orgs));
      return orgs?.find((org) => org?.org_id === data?.orgId);
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function transferToPartner(data) {
  return async (dispatch) => {
    try {
      dispatch(customersSlice.actions.startLoading());
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/transfer_org`,
        params: {
          org_id: data?.orgID,
          reseller_id: data?.partnerID,
        },
      });
      const { orgs } = response.data;
      dispatch(customersSlice.actions.getCustomersSuccess(orgs));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function moveOrgToBin(data) {
  return async (dispatch) => {
    try {
      dispatch(customersSlice.actions.startLoading());
      const response = await axios({
        method: 'delete',
        url: `/lms_user/api/org/${data?.orgID}`,
      });
      // console.log(response.data);
      const { orgs } = response.data;
      dispatch(customersSlice.actions.getCustomersSuccess(orgs));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function restoreOrgFromBin(data) {
  return async (dispatch) => {
    try {
      dispatch(customersSlice.actions.startLoading());
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/org/${data?.orgID}/restore`,
      });
      const { orgs } = response.data;
      dispatch(customersSlice.actions.getCustomersSuccess(orgs));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
