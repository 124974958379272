// ----------------------------------------------------------------------

export default function Tabs(theme) {
  return {
    MuiTabs: {
      defaultProps: {
        textColor: 'inherit',
        allowScrollButtonsMobile: true,
        variant: 'scrollable',
      },
      styleOverrides: {
        scrollButtons: {
          '&.MuiButtonBase-root': {
            width: 48,
            borderRadius: '50%',
            marginRight: '0px',
          },
        },
        vertical: {
          '& .MuiTab-root': {
            marginRight: theme.spacing(0),
            alignItems: 'start',
            borderTopLeftRadius: theme.shape.borderRadius,
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: 0,
            '&:not(:last-of-type)': {
              marginRight: theme.spacing(0),
              [theme.breakpoints.up('sm')]: {
                marginRight: theme.spacing(0),
              },
            },
          },
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
        iconPosition: 'start',
      },
      styleOverrides: {
        root: ({ ownerState }) => ({
          padding: '5px 10px',
          opacity: 1,
          minWidth: 48,
          fontWeight: theme.typography.fontWeightMedium,
          '&.Mui-selected': {
            color: theme.palette.primary.main,
            backgroundColor: '#eef3fb',
            // color: "#2065D1",
          },
          '&:not(:last-of-type)': {
            marginRight: theme.spacing(3),
            [theme.breakpoints.up('sm')]: {
              marginRight: theme.spacing(5),
            },
          },
          '&:not(.Mui-selected)': {
            color: theme.palette.text.secondary,
          },
          ...((ownerState.iconPosition === 'start' || ownerState.iconPosition === 'end') && {
            minHeight: 48,
          }),
        }),
      },
    },
  };
}
