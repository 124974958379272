import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  isLoading: false,
  error: null,
  login_url: null,
  accounts: [],
  customers: [],
  initialized: false,
  subscriptions: [],
  loadingSubscription: false,
  detailsLoader: false,
  subscriptionsDetails: [],
  selectedSubscriptionDetails: {},
  subscriptionInfoLoader: false,
  allTransactionsLoader: false,
  allTransactions: [],
};

const googleWorkspaceSlice = createSlice({
  name: 'googleWorkspace',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // STOP LOADING
    stopLoading(state) {
      state.isLoading = false;
    },

    // START SUBSCRIPTION LOADING
    startLoadingSubscription(state) {
      state.loadingSubscription = true;
    },
    // STOP SUBSCRIPTION LOADING
    stopLoadingSubscription(state) {
      state.loadingSubscription = false;
    },

    // START SUBSCRIPTION LOADING
    startDetailsLoader(state) {
      state.detailsLoader = true;
    },
    // STOP SUBSCRIPTION LOADING
    stopDetailsLoader(state) {
      state.detailsLoader = false;
    },

    // START SUBSCRIPTION INFO LOADING
    startInfoLoader(state) {
      state.subscriptionInfoLoader = true;
    },
    // STOP SUBSCRIPTION INFO LOADING
    stopInfoLoader(state) {
      state.subscriptionInfoLoader = false;
    },

    // START ALL TRANSACTIONS LOADING
    startAllTransactionsLoader(state) {
      state.allTransactionsLoader = true;
    },
    // STOP ALL TRANSACTIONS LOADING
    stopAllTransactionsLoader(state) {
      state.allTransactionsLoader = false;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET CUSTOMERS SUCCESS
    getCustomersSuccess(state, action) {
      const { login_url, accounts, customers = accounts[0]?.customers } = action.payload;
      state.isLoading = false;
      state.initialized = true;
      state.customers = customers;
      state.login_url = login_url;
      state.accounts = accounts;
    },

    // GET SUBSCRIPTIONS SUCCESS
    getSubscriptionsSuccess(state, action) {
      const { subscriptions } = action.payload;
      state.loadingSubscription = false;
      state.subscriptions = subscriptions;
    },

    // GET SUBSCRIPTIONS DETAILS SUCCESS
    getSubscriptionsDetailsSuccess(state, action) {
      const { response, selected } = action.payload;
      const { details } = response;
      state.detailsLoader = false;
      state.subscriptionsDetails = details;
      state.selectedSubscriptionDetails = selected;
    },

    // GET SUBSCRIPTIONS PAYMENT DETAILS SUCCESS
    getSubscriptionsPaymentDetailsSuccess(state, action) {
      const { details } = action.payload;
      state.subscriptionInfoLoader = false;
      state.subscriptionsDetails = details;
    },

    // GET UPDATE SUBSCRIPTIONS INFO SUCCESS
    getUpdateSubscriptionsInfoSuccess(state, action) {
      const { subscriptions } = action.payload;
      state.subscriptionInfoLoader = false;
      state.subscriptions = subscriptions;
    },

    // GET ALL TRANSACTIONS SUCCESS
    getAllTransactionsSuccess(state, action) {
      const { details } = action.payload;
      state.allTransactionsLoader = false;
      state.allTransactions = details;
    },
  },
});

// Actions
export const { getCustomersSuccess } = googleWorkspaceSlice.actions;

// Reducer
export default googleWorkspaceSlice.reducer;

export const googleWorkspaceState = (state) => state.googleWorkspace;

export function getAccountSetup() {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startLoading());
    try {
      const response = await axios.get('/lms_user/api/google-workspace/account_setup');
      dispatch(googleWorkspaceSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      dispatch(googleWorkspaceSlice.actions.hasError(error));
    }
  };
}

export function setupGoogleWorkspaceAccount(setupData) {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startLoading());
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/google-workspace/account_setup`,
        data: setupData,
      });
      dispatch(googleWorkspaceSlice.actions.getCustomersSuccess(response.data));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function assignOrganisation(customerId, orgId) {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startLoadingSubscription());
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/google-workspace/assign_org`,
        params: {
          customer_ids: customerId,
          org_id: orgId,
        },
      });
      dispatch(googleWorkspaceSlice.actions.getCustomersSuccess(response.data));
      dispatch(googleWorkspaceSlice.actions.stopLoadingSubscription());
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function getSubscriptions(customerId, accId) {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startLoadingSubscription());
    try {
      const response = await axios({
        method: 'get',
        url: `/lms_user/api/google-workspace/subscriptions`,
        params: {
          customer_id: customerId,
          account_id: accId,
        },
      });
      dispatch(googleWorkspaceSlice.actions.getSubscriptionsSuccess(response.data));
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

export function getSubscriptionsDetails(customerId, accId, subsId, selected) {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startDetailsLoader());
    try {
      const response = await axios({
        method: 'get',
        url: `/lms_user/api/google-workspace/subscription_details`,
        params: {
          customer_id: customerId,
          account_id: accId,
          subscription_id: subsId,
        },
      });
      dispatch(
        googleWorkspaceSlice.actions.getSubscriptionsDetailsSuccess({
          response: response.data,
          selected,
        })
      );
    } catch (error) {
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}

// export function updateSubscriptionInfo(
//   customerId,
//   accId,
//   subsId,
//   startDate,
//   endDate,
//   lf20,
//   lp20,
//   sf20,
//   sp20
// ) {
//   return async (dispatch) => {
//     dispatch(googleWorkspaceSlice.actions.startInfoLoader());
//     try {
//       const response = await axios({
//         method: 'post',
//         url: `/lms_user/api/google-workspace/subscription`,
//         params: {
//           customer_id: customerId,
//           account_id: accId,
//           subscription_id: subsId,
//           start_date: startDate,
//           end_date: endDate,
//           landing_first_20: lf20,
//           landing_post_20: lp20,
//           selling_first_20: sf20,
//           selling_post_20: sp20,
//         },
//       });
//       dispatch(googleWorkspaceSlice.actions.getSubscriptionsSuccess(response.data));
//       dispatch(googleWorkspaceSlice.actions.stopInfoLoader());
//     } catch (error) {
//       dispatch(googleWorkspaceSlice.actions.stopInfoLoader());
//       throw error;
//     }
//   };
// }

export function updateSubscriptionInfo(
  customerId,
  accId,
  subsId,
  startDate,
  endDate,
  first20,
  post20,
  priceType
) {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startInfoLoader());
    try {
      const response = await axios({
        method: 'post',
        url:
          priceType === 'cp'
            ? `/lms_user/api/google-workspace/subscription/cost_price`
            : priceType === 'sp' && `/lms_user/api/google-workspace/subscription/selling_price`,
        params:
          priceType === 'cp'
            ? {
                customer_id: customerId,
                account_id: accId,
                subscription_id: subsId,
                start_date: startDate,
                end_date: endDate,
                first_20: first20,
                post_20: post20,
              }
            : priceType === 'sp' && {
                customer_id: customerId,
                account_id: accId,
                subscription_id: subsId,
                start_date: startDate,
                end_date: endDate,
                first_20: first20,
                post_20: post20,
              },
      });
      dispatch(googleWorkspaceSlice.actions.getSubscriptionsSuccess(response.data));
      dispatch(googleWorkspaceSlice.actions.stopInfoLoader());
    } catch (error) {
      dispatch(googleWorkspaceSlice.actions.stopInfoLoader());
      throw error;
    }
  };
}

export function updatePaymentDetails(customerId, accId, subsId, entId, orderType, stts, desc) {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startInfoLoader());
    try {
      const response = await axios({
        method: 'post',
        url: `/lms_user/api/google-workspace/subscription_pricing`,
        params: {
          customer_id: customerId,
          account_id: accId,
          subscription_id: subsId,
          entitlement_id: entId,
          order_type: orderType,
          status: stts,
          description: desc,
        },
      });
      dispatch(googleWorkspaceSlice.actions.getSubscriptionsPaymentDetailsSuccess(response.data));
      dispatch(googleWorkspaceSlice.actions.stopInfoLoader());
    } catch (error) {
      dispatch(googleWorkspaceSlice.actions.stopInfoLoader());
      throw error;
    }
  };
}

export function getAllTransactions(accID, dateRange) {
  return async (dispatch) => {
    dispatch(googleWorkspaceSlice.actions.startAllTransactionsLoader());
    try {
      const response = await axios({
        method: 'get',
        url: `/lms_user/api/google-workspace/transactions`,
        params: {
          account_id: accID,
          start_date: dateRange?.startDate,
          end_date: dateRange?.endDate,
        },
      });
      dispatch(googleWorkspaceSlice.actions.getAllTransactionsSuccess(response.data));
      dispatch(googleWorkspaceSlice.actions.stopAllTransactionsLoader());
    } catch (error) {
      dispatch(googleWorkspaceSlice.actions.stopAllTransactionsLoader());
      throw new Error(Object.keys(error.errors).map((err) => error.errors[err]));
    }
  };
}
