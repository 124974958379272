import { Navigate, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER_LOGIN } from '../config-global';
//
import {
  Page404,
  ProfilePage,
  ManageAccount,
  LoginPage,
  RegisterPage,
  Customers,
  CustomerCreateEditPage,
  AccountManagerPage,
  Partners,
  PartnerCreateEditPage,
  PartnerAccountPage,
  Users,
  UserNewEditPage,
  WhatsAppSettingsPage,
  WhatsAppTemplatesPage,
  ReportsPage,
  GoogleWorkspace,
  CustomerInfo,
  Rates,
  Transactions,
  WhitelabelConfigPage,
  PartnersWallet,
  CustomersWallet,
  WalletTransactions,
  PartnerProfilePage,
  CustomizeDemoForm,
  FixDisplayName,
} from './elements';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <RegisterPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/manage-account/:accessToken/:refreshToken',
      element: <ManageAccount />,
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        {
          path: 'profile',
          element: <ProfilePage />,
        },
        {
          path: 'customers',
          children: [
            { element: <Customers />, index: true },
            { path: 'add-customer', element: <CustomerCreateEditPage /> },
            {
              path: 'edit/:name',
              element: <CustomerCreateEditPage />,
            },
            {
              path: 'account-manager',
              element: <AccountManagerPage />,
            },
          ],
        },
        {
          path: 'partners',
          children: [
            { element: <Partners />, index: true },
            { path: 'account-setup', element: <PartnerAccountPage /> },
            { path: 'profile', element: <PartnerProfilePage /> },
            { path: 'add-partner', element: <PartnerCreateEditPage /> },
            {
              path: 'edit/:name',
              element: <PartnerCreateEditPage />,
            },
          ],
        },
        // {
        //   path: 'google-workspace',
        //   children: [
        //     { element: <GoogleWorkspace />, index: true },
        //     { path: 'info', element: <CustomerInfo /> },
        //   ],
        // },
        {
          path: 'google-workspace',
          children: [
            {
              path: 'customers',
              children: [
                { element: <GoogleWorkspace />, index: true },
                { path: 'info', element: <CustomerInfo /> },
              ],
            },
            { path: 'transactions', element: <Transactions /> },
          ],
        },
        {
          path: 'users',
          children: [
            { element: <Users />, index: true },
            { path: 'add-user', element: <UserNewEditPage /> },
            {
              path: 'edit/:name',
              element: <UserNewEditPage />,
            },
          ],
        },
        {
          path: 'whatsapp',
          children: [
            { path: 'settings', element: <WhatsAppSettingsPage /> },
            {
              path: 'templates',
              element: <WhatsAppTemplatesPage />,
            },
            {
              path: 'rates',
              element: <Rates />,
            },
            {
              path: 'partners',
              element: <PartnersWallet />,
            },
            {
              path: 'customers',
              element: <CustomersWallet />,
            },
            {
              path: 'transactions',
              element: <WalletTransactions />,
            },
            {
              path: 'customize-demo',
              element: <CustomizeDemoForm />,
            },
            {
              path: 'fix-display-name',
              element: <FixDisplayName />,
            },
          ],
        },
        {
          path: 'reports',
          element: <ReportsPage />,
        },
        {
          path: 'whitelable-config',
          element: <WhitelabelConfigPage />,
        },
        // {
        //   path: 'leadsetup',
        //   element: <LeadSetup />,
        // },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
