import ReactDOM from 'react-dom/client';
import React, { Suspense, useEffect, useState } from 'react';
import axios from './utils/axios';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import LoadingScreen from './components/loading-screen';

// ----------------------------------------------------------------------

const fetchWhitelabelConfigData = async (setLoading) => {
  try {
    const url = new URL(window.location.href);
    const hostUrl = url.origin;
    const response = await axios.get('/lms_user/api/whitelable_config_display', {
      params: { host_url: hostUrl },
    });

    const {
      landing_logo: landingLogo,
      favicon_logo: faviconLogo,
      display_name: displayName,
      meta_title: metaTitle,
      meta_discription: metaDiscription,
      meta_keywords: metaKeywords,
      primary_color: primaryColor,
      secondry_color: secondaryColor,
    } = response.data || {};

    localStorage.setItem('landingLogo', landingLogo);
    localStorage.setItem('faviconLogo', faviconLogo);
    localStorage.setItem('displayName', displayName);
    localStorage.setItem('metaTitle', metaTitle);
    localStorage.setItem('metaDiscription', metaDiscription);
    localStorage.setItem('metaKeywords', metaKeywords);
    localStorage.setItem('primaryColor', primaryColor);
    localStorage.setItem('secondaryColor', secondaryColor);
  } catch (error) {
    console.log(error);
  } finally {
    setLoading(false);
  }
};

const RootComponent = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchWhitelabelConfigData(setLoading);
  }, []);

  if (loading) {
    return <Suspense fallback={<LoadingScreen />} />;
  }

  return <App />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(<RootComponent />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
