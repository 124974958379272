import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

const initialState = {
  initialized: false,
  error: null,
  reports: [],
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // SET REPORTS SUCCESS
    setReports(state, action) {
      state.initialized = true;
      state.reports = action?.payload || [];
    },
  },
});

// Actions
export const { setReports } = reportsSlice.actions;

// Reducer
export default reportsSlice.reducer;

export const getReports = (state) => state.reports;

export function getReportsFromApi() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`lms_user/api/partner/reports`);
      dispatch(reportsSlice.actions.setReports(response.data.report_list));
    } catch (error) {
      dispatch(reportsSlice.actions.hasError(error));
    }
  };
}

export function reportStatusChange(status, reportId) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `lms_user/api/partner/reports/${reportId}/optinout/${status}`
      );
      dispatch(reportsSlice.actions.setReports(response.data.report_list));
    } catch (error) {
      dispatch(reportsSlice.actions.hasError(error));
    }
  };
}

export function reportTemplateMapping(data) {
  return async (dispatch) => {
    try {
      const response = await axios({
        method: 'post',
        url: `lms_user/api/partner/reports/add`,
        params: data,
      });
      dispatch(reportsSlice.actions.setReports(response.data.report_list));
    } catch (error) {
      dispatch(reportsSlice.actions.hasError(error));
    }
  };
}
